/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
a {
  border-bottom: 1px solid rgba(35, 107, 151, 0.40);
  color: #236a97;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: border-bottom-color 0.2s ease, color 0.2s ease;
}

a:hover,
a:active,
a:focus {
  border-bottom-color: #236a97;
}

a svg,
a img {
  vertical-align: middle;
}

.link-no-underline {
  border-bottom-color: transparent !important;
}

.link-no-underline:hover {
  border-bottom-color: #236a97 !important;
}

.link-rating,
.link-rating:hover {
  border-bottom: 0 !important;
}
