/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
button.locations-navigator {
  display: flex;
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 2px;
  color: inherit;
  text-align: left;
}

button.locations-navigator:hover,
button.locations-navigator:active {
  border-color: #4B9FD5;
}

button.locations-navigator:focus {
  border-color: transparent;
}

button.locations-navigator.selected {
  border-color: #4B9FD5;
  background-color: #ECF6FE;
}
