/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */

/* TODO: should we move this? Or handle it differently? */
.navbar-inner-with-notif .info-drawer {
  border-top: 1px solid #e6e6e6;
}

.info-drawer-pane {
  background-color: white;
  right: -380px;
  width: 380px;
  transition: right 0.3s ease-in-out;
  border-left: 1px solid #e6e6e6;
  box-sizing: border-box;
}

.info-drawer-pane.open {
  right: 0;
}

.info-drawer {
  position: fixed;
  /* top is defined programmatically by ComponentNav */
  bottom: 0;
  z-index: 50;
}

.info-drawer .close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  padding: 16px;
  z-index: 2;
}

.info-drawer .back-button {
  cursor: pointer;
}

.info-drawer .back-button:hover {
  color: #4b9fd5;
}

.info-drawer-page {
  position: absolute;
  top: 0;
  bottom: 0;
}
