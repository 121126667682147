/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.line-chart-path {
  fill: none;
  stroke: #4b9fd5;
  stroke-width: 3px;
}

.line-chart-path.line-chart-path-1 {
  stroke: #236a97;
  stroke-dasharray: 3;
}

.line-chart-path.line-chart-path-2 {
  stroke: #24c6e0;
  stroke-dasharray: 10;
}

.line-chart-path.line-chart-path-legend.line-chart-path-2 {
  stroke-dasharray: 7;
}

.line-chart-area {
  fill: rgba(75, 159, 213, 0.3);
  stroke-width: 0;
}

.line-chart-area.line-chart-area-1 {
  fill: rgba(35, 106, 151, 0.3);
}

.line-chart-area.line-chart-area-2 {
  fill: rgba(36, 198, 224, 0.3);
}

.line-chart-point {
  fill: #fff;
  stroke: #4b9fd5;
  stroke-width: 2px;
}

.line-chart-tick {
  fill: #656565;
  font-size: 12px;
}

.line-chart-tick-x {
  text-anchor: end;
}

.line-chart-tick-x-right {
  text-anchor: start;
}

.line-chart-grid {
  shape-rendering: crispedges;
  stroke: #eee;
}
