/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.popup {
  position: absolute;
  z-index: 5000;
  margin-top: -16px;
  margin-left: 8px;
  padding: 8px;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0
6px
12px
rgba(0, 0, 0, 0.175);
  cursor: default;
}

.popup.no-padding {
  padding: 0;
}

/* #region .popup-arrow */
.popup-arrow,
.popup-arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: 6px solid transparent;
}

.popup-arrow {
  top: 15px;
  left: -6px;
  border-left-width: 0;
  border-right-color: #e6e6e6;
}

.popup-arrow:after {
  content: ' ';
  left: 1px;
  bottom: -6px;
  border-left-width: 0;
  border-right-color: #ffffff;
}
/* #endregion */

/* #region .popup.is-bottom */
.popup.is-bottom {
  top: 100%;
  left: 0;
  margin: 0;
  margin-left: 50%;
  transform: translate(-50%, 6px);
}

.popup.is-bottom .popup-arrow {
  top: -6px;
  left: calc(50% - 6px);
  border-left-width: 6px;
  border-top-width: 0;
  border-right-color: transparent;
  border-bottom-color: #e6e6e6;
}

.popup.is-bottom .popup-arrow.is-left {
  left: 8px;
}

.popup.is-bottom .popup-arrow:after {
  left: -6px;
  bottom: -7px;
  border-left-width: 6px;
  border-top-width: 0;
  border-right-color: transparent;
  border-bottom-color: #ffffff;
}
/* #endregion */

/* #region .popup.is-bottom-right */
.popup.is-bottom-right {
  top: 100%;
  right: 0;
  margin: 0;

  /* TODO Update like .is-bottom-left, currently it's */
  transform: translateY(6px);
}

.popup.is-bottom-right .popup-arrow {
  top: -6px;
  left: auto;
  right: 8px;
  border-left-width: 6px;
  border-top-width: 0;
  border-right-color: transparent;
  border-bottom-color: #e6e6e6;
}

.popup.is-bottom-right .popup-arrow:after {
  left: -6px;
  bottom: -7px;
  border-left-width: 6px;
  border-top-width: 0;
  border-right-color: transparent;
  border-bottom-color: #ffffff;
}
/* #endregion */

/* #region .popup.is-bottom-left */
.popup.is-bottom-left {
  top: 100%;
  left: 0;
  margin: 0;
  transform: translate(-8px, 6px);
}

.popup.is-bottom-left .popup-arrow {
  top: -6px;
  right: auto;
  left: 8px;
  border-left-width: 6px;
  border-top-width: 0;
  border-right-color: transparent;
  border-bottom-color: #e6e6e6;
}

.popup.is-bottom-left .popup-arrow:after {
  left: -6px;
  bottom: -7px;
  border-left-width: 6px;
  border-top-width: 0;
  border-right-color: transparent;
  border-bottom-color: #ffffff;
}
/* #endregion */

/* #region .popup.is-left-top */
.popup.is-left-top {
  top: -4px;
  right: 100%;
  margin: 0;
  transform: translateX(-6px);
}

.popup.is-left-top .popup-arrow {
  right: -6px;
  left: auto;
  top: 8px;
  border-right-width: 0;
  border-left-width: 6px;
  border-left-color: #e6e6e6;
  border-right-color: transparent;
}

.popup.is-left-top .popup-arrow:after {
  top: -6px;
  left: -7px;
  border-right-width: 0;
  border-left-width: 6px;
  border-left-color: #ffffff;
  border-right-color: transparent;
}
/* #endregion */

/* #region .popup.is-right-top */
.popup.is-right-top {
  top: -4px;
  left: 100%;
  margin: 0;
  transform: translateX(6px);
}

.popup.is-right-top .popup-arrow {
  left: -6px;
  right: auto;
  top: 8px;
  border-left-width: 0;
  border-right-width: 6px;
  border-right-color: #e6e6e6;
  border-left-color: transparent;
}

.popup.is-right-top .popup-arrow:after {
  top: -6px;
  right: -7px;
  border-left-width: 0;
  border-right-width: 6px;
  border-right-color: #ffffff;
  border-left-color: transparent;
}
/* #endregion */

/* #region .popup.is-right-bottom */
.popup.is-right-bottom {
  bottom: 4px;
  left: 100%;
  margin: 0;
  transform: translateX(6px);
}

.popup.is-right-bottom .popup-arrow {
  left: -6px;
  right: auto;
  top: calc(100% - 15px);
  border-left-width: 0;
  border-right-width: 6px;
  border-right-color: #e6e6e6;
  border-left-color: transparent;
}

.popup.is-right-bottom .popup-arrow:after {
  top: -6px;
  right: -7px;
  border-left-width: 0;
  border-right-width: 6px;
  border-right-color: #ffffff;
  border-left-color: transparent;
}
/* #endregion */

/* #region .popup.is-top-left */
.popup.is-top-left {
  bottom: calc(100% + 8px);
  left: 0;
  margin: 0;
  transform: translateX(-8px);
}

.popup.is-top-left .popup-arrow {
  bottom: -6px;
  top: auto;
  left: 8px;
  border-color: #e6e6e6 transparent transparent;
  border-width: 6px 6px 0 6px;
}

.popup.is-top-left .popup-arrow:after {
  left: -6px;
  top: -7px;
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent;
}
/* #endregion */

/* #region .popup & .menu or .multi-select */
.popup:not(.no-padding) > .menu,
.popup:not(.no-padding) > .multi-select {
  margin: -8px;
}
/* #endregion */

/* #region .popup-portal override css placement */
.popup-portal .popup.is-bottom {
  top: unset;
  left: unset;
  transform: unset;
  margin: 0;
}

.popup-portal .popup.is-bottom-left,
.popup-portal .popup.is-bottom-right,
.popup-portal .popup.is-top-left,
.popup-portal .popup.is-left-top,
.popup-portal .popup.is-right-top,
.popup-portal .popup.is-right-bottom {
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
}
/* #endregion */
