/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
/* TODO this file is HUGE and should be cut into smaller components */

.search-navigator.sticky .search-navigator-workspace-header {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 300px;
  right: 0;
}

.search-navigator.sticky .search-navigator-workspace-list,
.search-navigator.sticky .search-navigator-workspace-details {
  padding-top: 43px;
}

.search-navigator-facet-box {
  background-color: #f3f3f3;
  font-size: 13px;
}

.search-navigator-facet-box.leak-facet-box {
  background-color: #fbf3d5;
  border: 1px solid #f1e8cb;
}

.search-navigator-facet-box.is-inner {
  margin-left: 8px;
  padding-left: 12px;
  border-left: 1px solid #e6e6e6;
}

.search-navigator-facet-box.is-inner .search-navigator-facet-header {
  padding-top: 6px;
  padding-bottom: 6px;
}

.leak-facet-box:not(.hidden) + .leak-facet-box {
  border-top: none;
}

.search-navigator-facet-box-forbidden {
  background-color: transparent;
  opacity: 0.5;
}

.search-navigator-facet-box-forbidden .search-navigator-facet-list,
.search-navigator-facet-box-forbidden .search-navigator-facet-empty,
.search-navigator-facet-box-forbidden .search-navigator-facet-container {
  display: none;
}

.search-navigator-facet-box-forbidden .search-navigator-facet-header {
  color: #656565;
}

.search-navigator-facet-box-forbidden .search-navigator-facet-header:hover {
  color: #4b9fd5;
}

.search-navigator-facet-box-forbidden .search-navigator-facet-header {
  cursor: default;
}

.search-navigator-facet-box-forbidden .search-navigator-facet-header:hover {
  color: #656565;
}

.search-navigator-facet,
button.search-navigator-facet {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 24px;
  margin: 0 0 1px 0;
  padding: 0 6px;
  border: 1px solid transparent;
  border-radius: 2px;
  box-sizing: border-box;
  white-space: normal;
  opacity: 0.3;
  cursor: not-allowed;
  transition: none;
}

button.search-navigator-facet {
  opacity: 1;
  cursor: pointer;
}

button.search-navigator-facet .facet-name {
  color: #333;
}

button.search-navigator-facet:hover,
button.search-navigator-facet:focus,
.search-navigator-facet.active {
  border-color: #4b9fd5;
}

.search-navigator-facet.facet-category {
  opacity: 1;
  cursor: default;
}

.search-navigator-facet.facet-category .facet-name {
  color: #656565;
}

.search-navigator-facet .facet-name {
  flex: 1 1 auto;
  min-width: 0;
  line-height: 16px;
  padding: 1px 0; /* needed to fit small ratings and levels */
  color: #656565;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-navigator-facet .facet-stat {
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: #656565;
  font-size: 12px;
}

.search-navigator-facet .facet-toggle {
  display: none;
  float: left;
  height: 16px;
  line-height: 16px;
  margin-top: -1px;
  padding: 0 5px;
  border-radius: 2px;
  font-size: 11px;
  text-transform: lowercase;
}

.search-navigator-facet .facet-toggle:hover {
  color: #333;
}

.search-navigator-facet .facet-toggle-active.facet-toggle-green {
  background-color: #00aa00;
  color: #ffffff;
}

.search-navigator-facet .facet-toggle-active.facet-toggle-red {
  background-color: #D02F3A;
  color: #ffffff;
}

.leak-facet-box .search-navigator-facet .facet-name {
  background-color: #fbf3d5;
}

.leak-facet-box .search-navigator-facet .facet-stat {
  background-color: #fbf3d5;
}

.leak-facet-box .search-navigator-facet .facet-stat:before {
  background-image: linear-gradient(to right, rgba(251, 243, 213, 0), #fbf3d5 75%);
}

.search-navigator-facet.active {
  background-color: #f2faff;
  text-decoration: none;
}

.search-navigator-facet.active .facet-toggle {
  display: inline;
}

.search-navigator-facet.compare .facet-toggle {
  cursor: not-allowed;
  opacity: 0.5;
}

.search-navigator-facet.compare .facet-toggle.facet-toggle-green {
  background-color: #00aa00;
  color: #ffffff;
}

.search-navigator-facet.compare .facet-toggle.facet-toggle-red {
  background-color: transparent;
  color: #656565;
}

.search-navigator-facet-half {
  display: inline-flex;
  width: 45%;
}

.search-navigator-facet-half:nth-child(odd) {
  margin-right: 10%;
}

.search-navigator-facet-header {
  display: block;
  flex-shrink: 0;
  padding: 8px 1px;
  color: #333;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
}

.search-navigator-facet-header > button {
  border-bottom: none;
  color: #333;
  cursor: pointer;
  font-weight: inherit;
}

.search-navigator-facet-header > button:focus,
.search-navigator-facet-header > button:hover {
  color: #236a97;
}

.search-navigator-facet-header > .note {
  font-weight: 400;
}

.search-navigator-facet-header > h3,
.search-navigator-facet-header > h4 {
  line-height: inherit;
  display: inline;
}

.search-navigator-facet-header-value {
  display: block;
  overflow: hidden;
}

.search-navigator-facet-header-value > .badge {
  display: block;
}

.search-navigator-facet-header-button {
  flex-shrink: 0;
  margin-left: auto;
}

.search-navigator-facet-header-wrapper {
  display: flex;
  align-items: center;
}

.search-navigator-facet-list {
  padding-bottom: 8px;
  font-size: 0;
}

.search-navigator-facet-list-title {
  margin: 0 8px 4px;
  font-size: 12px;
  font-weight: bold;
}

.search-navigator-facet-list + .search-navigator-facet-list > .search-navigator-facet-list-title {
  border-top: 1px solid #e6e6e6;
  padding-top: 8px;
}

.search-navigator-facet-empty {
  margin: 0 0 0 0;
  padding: 0 10px 10px;
  color: #333;
  font-size: 12px;
}

.search-navigator-facet-footer {
  display: block;
  padding-bottom: 8px;
  border-bottom: none;
}

.search-navigator-facet-container {
  margin-top: 6px;
  padding: 0 10px 16px;
}

.search-navigator-date-facet-selection {
  position: relative;
  padding-left: 8px;
  font-size: 12px;
}

.search-navigator-date-facet-selection:before,
.search-navigator-date-facet-selection:after {
  display: table;
  content: '';
  line-height: 0;
}

.search-navigator-date-facet-selection:after {
  clear: both;
}

.search-navigator-date-facet-selection .date-input-control-input {
  width: 115px !important;
}

.search-navigator-date-facet-selection-dropdown-left {
  float: left;
  border-bottom: none;
}

.search-navigator-date-facet-selection-dropdown-right {
  float: right;
  border-bottom: none;
}

.search-navigator-date-facet-selection-input-left {
  position: absolute;
  left: 0;
  width: 100px;
  visibility: hidden;
}

.search-navigator-date-facet-selection-input-right {
  position: absolute;
  right: 0;
  width: 100px;
  visibility: hidden;
}

.search-navigator-date-facet-selection
  .date-input-control:not(:nth-of-type(1))
  .date-input-calendar {
  left: auto;
  right: 0;
}

.search-navigator-filters {
  position: relative;
  padding: 5px 10px;
  background-color: #f3f3f3;
}

.search-navigator-filters:before,
.search-navigator-filters:after {
  display: table;
  content: '';
  line-height: 0;
}

.search-navigator-filters:after {
  clear: both;
}

.search-navigator-filters-header {
  margin-bottom: 12px;
  padding-bottom: 11px;
  border-bottom: 1px solid #e6e6e6;
}
.search-navigator-intro {
  width: 500px;
  margin: 0 auto;
  padding-top: 100px;
}
