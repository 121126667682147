/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.marketplace-editions {
  display: flex;
  flex-direction: row;
  margin-left: -8px;
  margin-right: -8px;
}

.marketplace-edition {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
  margin-right: 8px;
  max-width: 50%;
}

.marketplace-edition .markdown img {
  width: 16px;
}

.marketplace-edition .markdown h3 {
  font-size: 14px;
  margin-top: 0;
}

.marketplace-edition-badge {
  position: absolute;
  right: -1px;
  top: 16px;
  padding: 4px 8px;
  border-radius: 2px 0 0 2px;
  height: inherit;
  line-height: inherit;
}

.marketplace-edition-action {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.marketplace-plugin-table {
  table-layout: fixed;
  width: 100%;
}

.marketplace-plugin-license {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
