/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.icon-radio {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 14px;
  height: 14px;
  margin: 1px;
  border: 1px solid #888;
  border-radius: 12px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  flex-shrink: 0;
}

.icon-radio:after {
  position: absolute;
  top: 2px;
  left: 2px;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #236a97;
  content: '';
  opacity: 0;
  transition: opacity 0.3s ease;
}

.link-radio .icon-radio.is-checked:after {
  opacity: 1;
}

.link-radio {
  color: inherit;
  border-bottom: none;
}

.link-radio:not(.disabled):hover,
.link-radio:not(.disabled):active,
.link-radio:not(.disabled):focus {
  color: inherit;
}

.link-radio:not(.disabled):hover > .icon-radio {
  border-color: #4b9fd5;
}

.link-radio.disabled,
.link-radio.disabled:hover,
.link-radio.disabled label {
  color: #bbb;
  cursor: not-allowed;
}

.link-radio.disabled .icon-radio:after {
  background-color: #ebebeb;
}
