/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.system-info-health-title {
  margin-top: 24px;
  margin-bottom: 16px;
}

.system-info-health-info {
  margin-top: -12px;
}

.system-info-copy-paste-id-info {
  max-width: 550px;
  padding: 8px 16px;
  clear: both;
  line-height: 1.8;
}

.system-info-health-info .status-indicator {
  position: relative;
  top: 8px;
}

.system-info-health-info.no-margin .status-indicator {
  margin: 0;
}

.system-info-section ~ .system-info-section {
  margin-top: 16px;
}

.system-info-section-item-name {
  width: 25vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.system-upgrade-version {
  padding: 8px;
}

.system-upgrade-version ~ .system-upgrade-version {
  margin-top: 10px;
  padding-top: 18px;
  border-top: solid 1px #e6e6e6;
}

.system-upgrade-intermediate {
  padding: 6px 10px;
}
