/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.source-viewer {
  width: 100%;
  min-height: 200px;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
}

.source-table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.source-viewer-header {
  position: relative;
  padding: 2px 10px 4px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #f3f3f3;
}

.source-viewer-header-measure {
  vertical-align: middle;
  font-size: 13px;
}

.source-viewer-header-measure .rating {
  font-size: 18px;
}

.source-viewer-header-measure-separator {
  margin: 0 24px;
  height: 30px;
  border-right: 1px solid #cdcdcd;
}

.source-viewer-header-measure + .source-viewer-header-measure {
  margin-left: 24px;
}

.source-viewer-header-measure-label {
  display: block;
  line-height: 12px;
  color: #656565;
  font-size: 12px;
}

.source-viewer-header-measure-value {
  display: block;
  margin-top: 2px;
  line-height: 18px;
  color: #333;
  font-size: 16px;
}

.source-viewer-header-actions {
  display: block;
  margin-left: 24px;
  padding: 8px 4px;
}

.source-viewer-header-actions svg {
  margin-top: 2px;
}

.source-viewer-header-more-actions {
  position: absolute;
  z-index: 7500;
  right: 0;
  top: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-right: none;
  background-color: #fff;
  line-height: 1.8;
}

.source-viewer-code {
  overflow-x: auto;
}

.source-viewer-more-code {
  padding: 40px 0;
  border-bottom: 1px solid #e6e6e6;
  background-color: #f3f3f3;
  text-align: center;
}

.source-viewer-more-code .spinner {
  top: -1px;
}

.source-table + .source-viewer-more-code {
  border-bottom: none;
  border-top: 1px solid #e6e6e6;
}

.issue-location.highlighted {
  border-color: #e1e1f2;
  background-color: #e1e1f2;
}

.issue-location.selected {
  border-color: #f4b1b0;
  background-color: #f4b1b0;
}

.measures-viewer {
  display: flex;
  margin: 0 -8px;
}

.measures-viewer + .measures-viewer {
  margin-top: 8px;
}

.measures-viewer-header {
  font-size: 12px;
}

.measures-viewer-secondary .measures-viewer-card {
  background-color: #f3f3f3;
}

.measures-viewer-section {
  flex: 1;
}

.measures-viewer-section-limited {
  max-width: 25%;
}

.measures-viewer-card {
  margin: 16px 8px;
  padding: 8px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

.measures + .measures {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e6e6e6;
}

.measures-chart {
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  margin-right: 24px;
  text-align: center;
}

.measure {
  line-height: 1.34;
}

.measure-one-line {
  display: flex;
  justify-content: space-between;
}

.measure-one-line .measure-name {
  display: inline;
}

.measure-name {
  display: block;
  font-size: 13px;
}

.measure-value {
  color: #236a97;
  font-size: 16px;
  font-weight: 400;
}

.measure-big {
  display: inline-block;
  vertical-align: middle;
}

.measure-big .measure-name {
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 16px;
}

.measure-big .measure-value,
.measure-big .rating {
  font-size: 24px;
}

.measure-big + .measure-big {
  margin-left: 24px;
}
