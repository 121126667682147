/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.badges-list {
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}

.button.badge-button {
  display: flex;
  justify-content: center;
  padding: 8px;
  min-width: 146px;
  height: 116px;
  background-color: #f3f3f3;
  border: solid 1px #e6e6e6;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.button.badge-button:hover,
.button.badge-button:focus,
.button.badge-button:active {
  background-color: #f3f3f3;
  border-color: #4b9fd5;
}

.button.badge-button.selected {
  background-color: #cae3f2;
  border-color: #236a97;
}
